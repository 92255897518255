<template>
  <div>
    <!-- Header-->
    <header class="game bg-primary bg-gradient text-white">
      <div class="container px-4 py-5 text-center">
        <img
          v-if="gameDetails.GImage === null"
          src="../assets/images/default-thumbnail.png"
          class="w-100 game-image"
          alt="banner"
        />
        <img
          v-else
          :src="gameDetails.GImage"
          class="w-100 game-image"
          alt="banner"
        />
      </div>
    </header>

    <section class="bg-white py-3">
      <div class="container">
        <div class="row">
          <div class="col-3 game-details-specs">
            <p class="labelDesc">Size</p>
            <p class="fieldDesc">{{ gameDetails.Size }}</p>
          </div>
          <!--  -->
          <div class="col-3 game-details-specs">
            <p class="labelDesc">View</p>
            <p class="fieldDesc">N/A</p>
          </div>
          <!--  -->
          <div class="col-3 game-details-specs">
            <p class="labelDesc">Category</p>
            <p class="fieldDesc">{{ gameDetails.Category }}</p>
          </div>
          <!--  -->
          <div class="col-3 text-center">
            <p class="labelDesc">Android Version</p>
            <p class="fieldDesc">{{ gameDetails.Compatible }}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="mb-5">
      <div class="container mb-3">
        <div class="row">
          <div class="col-lg-12">
            <div class="my-4">
              <h1 class="fw-bolder text-left">
                {{ gameDetails.Gname }}
              </h1>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="my-4">
              <h3 class="text-uppercase text-left">Description</h3>
              <div class="horizontal-divider m-0"></div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="my-4">
              <a
                class="btn btn-candy-grape btn-dl px-5 float-right"
                href="#download"
                @click.prevent="downloadGame()"
                >Download <i class="fa fa-download"></i>
              </a>
            </div>
          </div>

          <div class="col-lg-12">
            <p class="text-justify mt-3">
              {{ gameDetails.Gdescription }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      gameDetails: {},
      campaignId: "",
      ip: "",
    };
  },

  methods: {
    async getGameDetails(id) {
      await this.$api
        .get(`/funbox/${id}`)
        .then((response) => {
          // console.log(response);
          this.gameDetails = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async downloadGame() {
      // console.log(id)
      await this.$api
        .get(`/FunboxDownload/${this.$route.params.id}`)
        .then(() => {
          // console.log(response);
          var fileLink = document.createElement("a");
          fileLink.href = "//" + this.gameDetails.Glink.split("//")[1];
          fileLink.setAttribute("download", "apk");
          document.body.appendChild(fileLink);
          fileLink.click();
          // this.gameDetails = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    downloadGame2() {
      // this.$swal(
      //   "Message",
      //   "To download and play this game, you need to subscribe to Funbox by sending FUNBOX ON to 8933. Please click the button below to continue",
      //   "info"
      // ).then(() => {
      var fileLink = document.createElement("a");
      fileLink.href = "sms://8933?body=FUNBOX ON";
      // fileLink.setAttribute("download", "apk");
      document.body.appendChild(fileLink);
      fileLink.click();
      // });
    },

    downloadGame3() {
      let cId = "";
      if (this.campaignId == "gp1") {
        cId = "FB1";
      } else if (this.campaignId == "gp2") {
        cId = "FB2";
      } else if (this.campaignId == "gp3") {
        cId = "FB3";
      } else if (this.campaignId == "gp4") {
        cId = "FB4";
      } else if (this.campaignId == "gp5") {
        cId = "FB5";
      } else {
        cId = "FB1";
      }

      const clickId = this.$store.getters.GET_CLICKID;
      var fileLink = document.createElement("a");
      fileLink.href = `sms://8933?body=${cId} ON ${clickId}`;
      document.body.appendChild(fileLink);
      fileLink.click();
    },

    downloadGame4() {
      this.$store
        .dispatch("ADD_SELECTEDGAME", this.gameDetails)
        .then(() => {
          this.$router.push({ path: "/download" });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    generateOTP() {
      var digits = "0123456789";
      let OTP = "";
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
      }
      return OTP;
    },

    async send(sp, pixel, pub, pay) {
      const {
        data: { ip },
      } = await axios.get("https://www.cloudflare.com/cdn-cgi/trace", {
        responseType: "text",
        transformResponse: (data) =>
          Object.fromEntries(
            data
              .trim()
              .split("\n")
              .map((line) => line.split("="))
          ),
      });

      await this.$api
        .get(
          `/ADNETWORK_FUNBOX?campaignID=${this.campaignId}&SP=${sp}&PIXEL=${pixel}&PUB=${pub}&PAY=${pay}&ip=${ip}`
        )
        .then((response) => {
          // console.log(response.data.clickID);
          const clickId = response.data.clickID;
          this.$store.dispatch("ADD_CLICKID", clickId);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getIpAddress() {
      // if (!localStorage.getItem("ipAddress")) {
      const {
        data: { ip },
      } = await axios.get("https://www.cloudflare.com/cdn-cgi/trace", {
        responseType: "text",
        transformResponse: (data) =>
          Object.fromEntries(
            data
              .trim()
              .split("\n")
              .map((line) => line.split("="))
          ),
      });

      this.ip = ip;
      // }
    },
  },

  computed: {
    // clickId() {
    //   return this.$store.getters.GET_CLICKID;
    // },
  },

  created() {
    this.getGameDetails(this.$route.params.id);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // console.log(this.ip);
    // this.getIpAddress()
    const query = this.$route.query;
    if (Object.keys(query).length) {
      this.send(query.sp, query.pixel, query.pub, query.pay);
    }
  },

  mounted() {
    const host = window.location.host;
    const parts = host.split(".");
    // this.getIpAddress();

    let cId = "";
    if (parts[0] == "gp1") {
      cId = "FB1";
    } else if (parts[0] == "gp2") {
      cId = "FB2";
    } else if (parts[0] == "gp3") {
      cId = "FB3";
    } else if (parts[0] == "gp4") {
      cId = "FB4";
    } else if (parts[0] == "gp5") {
      cId = "FB5";
    } else {
      cId = "FB1";
    }
    this.campaignId = cId;
  },
};
</script>
